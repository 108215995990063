/** @jsx jsx */
import { jsx, useColorMode, ThemeProvider } from 'theme-ui';
import { graphql } from 'gatsby';
import { ProgramLayout } from '../layouts/ProgramLayout';
import { H1, P, Text } from '../components/Text';
import moment from 'moment-timezone';
import { Program } from './ProgramDetails';
import { Container } from '../components/Container';
import { Box, Flex, SxProps } from 'rebass';
import React from 'react';
import { LocalizedLink } from '../components/LocalizedLink';
import { useTranslation } from '../i18n/TranslationContext';

interface ProgramDayProps {
  pageContext: {
    day: string;
    programs: any;
    programDays: string[];
  };
  data: any;
}

interface SingleProgramProps {
  program: any;
  localStartMoments: moment.Moment[];
  speakerImages: Record<string, string>;
}

const marineGreenTheme = {
  text: '#002E3B',
  background: '#6EFF96',
  modes: {
    dark: {
      text: '#6EFF96',
      background: '#002E3B',
    },
  },
};

const keynoteTheme = {
  text: '#000',
  background: '#FF00FF',
  modes: {
    dark: {
      text: '#FF00FF',
      background: '#000',
    },
  },
};

const ixdaTheme = {
  text: '#000',
  background: '#FFF',
  modes: {
    dark: {
      text: '#FFF',
      background: '#000',
    },
  },
};

const pinkPurpleTheme = {
  text: '#230055',
  background: '#f0f',
  modes: {
    dark: {
      text: '#f0f',
      background: '#230055',
    },
  },
};

const themeForKind = (kind: string) => {
  switch (kind) {
    case 'talk':
    case 'roundtable':
    case 'panel':
      return marineGreenTheme;
    case 'keynote':
    case 'qa':
      return keynoteTheme;
    case 'session':
    case 'workshop':
      return pinkPurpleTheme;
    case 'ixda':
      return ixdaTheme;
    default:
      return {};
  }
};

interface TimesStackProps {
  times: moment.Moment[];
  color: string | string[];
}
export const TimesStack: React.FC<TimesStackProps> = ({ times, color }) => {
  const t = useTranslation();

  return (
    <Flex sx={{ flexDirection: 'column', minWidth: '160px' }}>
      {times.map((time, i) => {
        return (
          <Text
            sx={{
              mb: 0,
              display: 'block',
              color: color,
            }}
            key={i}
          >
            {time.format(t('program_time_format_session'))}
          </Text>
        );
      })}
    </Flex>
  );
};

export const DayThemes = ['Workshops', 'Anger', 'Accountability', 'Action'];

const SimpleDayProgram: React.FC<SingleProgramProps> = ({
  program,
  localStartMoments,
}) => {
  const [colorMode, setColorMode] = useColorMode();
  return (
    <li
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
        mb: [3, 3, 2],
      }}
    >
      <Flex
        sx={{
          flex: '1',
          flexDirection: 'column',
          color: ['text', 'text', 'background'],
          py: [2, 2, 0],
          px: [3, 3, 0],
          backgroundColor: ['background', 'background', 'transparent'],
          borderColor: ['text', 'text', 'background'],
          borderBottom: ['solid 1px', 'solid 1px', 'none'],
        }}
      >
        <TimesStack
          times={localStartMoments}
          color={[
            'text',
            'text',
            colorMode === 'light' ? 'text' : 'background',
          ]}
        />
      </Flex>
      <Box
        sx={{
          display: 'block',
          flex: '3',
          px: 3,
          py: 2,
          backgroundColor: 'background',
          '&:focus': {
            outline: 'none',
            boxShadow: theme =>
              `0 0 0 2px ${theme.colors.background}, 0 0 0 6px ${theme.colors.text}`,
          },
        }}
      >
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Box>
            <h3
              sx={{
                display: 'block',
                variant: 'text.body',
                fontSize: 2,
                fontWeight: 'bold',
                color: 'text',
                my: 0,
              }}
            >
              {program.title}
            </h3>
            {program.resume && (
              <P sx={{ color: 'text', mb: 4 }}>{program.resume}</P>
            )}
          </Box>
        </Flex>
      </Box>
    </li>
  );
};

const TalkDayProgram: React.FC<SingleProgramProps> = ({
  program,
  localStartMoments,
  speakerImages,
}) => {
  const [colorMode, setColorMode] = useColorMode();

  return (
    <li
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
        mb: [3, 3, 2],
      }}
    >
      <Box
        sx={{
          flex: '1',
          color: ['text', 'text', 'background'],
          py: [2, 2, 0],
          px: [3, 3, 0],
          backgroundColor: ['background', 'background', 'transparent'],
          borderColor: ['text', 'text', 'background'],
          borderBottom: ['solid 1px', 'solid 1px', 'none'],
        }}
      >
        <TimesStack
          times={localStartMoments}
          color={[
            'text',
            'text',
            colorMode === 'light' ? 'text' : 'background',
          ]}
        />
      </Box>
      <LocalizedLink
        to={'/program/' + program.slug}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: '3',
          px: 3,
          py: 2,
          backgroundColor: 'background',
          textDecoration: 'none',
          transition: 'transform 0.125s',
          '&:hover, &:focus': { transform: 'scale(1.01)' },
          '&:focus': {
            outline: 'none',
            boxShadow: theme =>
              `0 0 0 2px ${theme.colors.background}, 0 0 0 6px ${theme.colors.text}`,
          },
          '&:active': {
            transform: 'scale(0.99)',
          },
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column', 'column', 'row'],
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ flexShrink: 1 }}>
            <h3
              sx={{
                variant: 'text.body',
                fontSize: 3,
                fontWeight: 'bold',
                color: 'text',
                my: 0,
              }}
            >
              {program.title}
            </h3>
            <P sx={{ color: 'text', mb: 4 }}>
              <span>
                {program.speaker_1_name}
                {program.speaker_2_name ? `, ${program.speaker_2_name}` : ''}
              </span>
            </P>
            {program.resume && (
              <P sx={{ color: 'text', mb: 4 }}>{program.resume}</P>
            )}
          </Box>
          <Flex
            sx={{
              flexDirection: ['row', 'row', 'column'],
              flexShrink: 0,
              ml: [0, 0, 2],
              width: ['100%', '100%', 'auto'],
              justifyContent: ['space-between', 'flex-start', 'flex-start'],
            }}
          >
            <Flex
              sx={{
                justifyContent: ['flex-start', 'flex-start', 'space-between'],
                alignItems: ['flex-end', 'flex-end', 'auto'],
              }}
            >
              <span
                sx={{
                  variant: 'text.body',
                  color: 'text',
                  textTransform: 'uppercase',
                }}
              >
                {program.kind}
              </span>
              <span
                sx={{
                  variant: 'text.body',
                  color: 'text',
                  textAlign: 'left',
                  fontWeight: 700,
                  ml: 2,
                }}
              >
                {program.format}
              </span>
            </Flex>
            {program.speaker_1_photo && (
              <Flex
                sx={{
                  flexDirection: ['row', 'row', 'column'],
                  height: '100%',
                  minWidth: '118px',
                  justifyContent: 'flex-end',
                }}
              >
                {program.speaker_1_photo && (
                  <img
                    srcSet={
                      speakerImages[program.speaker_1_photo] ??
                      speakerImages['placeholder.jpg']
                    }
                    sx={{
                      width: '118px',
                      height: '118px',
                      alignSelf: 'flex-end',
                    }}
                  />
                )}
                {program.speaker_2_photo && (
                  <img
                    srcSet={
                      speakerImages[program.speaker_2_photo] ??
                      speakerImages['placeholder.jpg']
                    }
                    sx={{
                      mt: [0, 0, 2],
                      ml: [2, 0, 0],
                      width: '118px',
                      height: '118px',
                      alignSelf: 'flex-end',
                    }}
                  />
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
      </LocalizedLink>
    </li>
  );
};

interface ProgramsListProps {
  programs: Program[];
  tzOffset: number;
  speakerImages: Record<string, string>;
}

const ProgramsList: React.FC<ProgramsListProps> = ({
  programs,
  tzOffset,
  speakerImages,
}) => {
  return (
    <React.Fragment>
      {programs.map((p: any, i: number) => {
        const startDates = [p.start_date_1, p.start_date_2, p.start_date_3];
        const localStartMoments = startDates
          .filter(d => d !== '')
          .map(d => moment(d + '-05:00').utcOffset(tzOffset));

        let program = null;

        switch (p.kind) {
          case 'talk':
          case 'keynote':
          case 'panel':
          case 'roundtable':
          case 'workshop':
            program = (
              <TalkDayProgram
                program={p}
                speakerImages={speakerImages}
                localStartMoments={localStartMoments}
              />
            );
            break;
          case 'ixda':
            program = (
              <SimpleDayProgram
                program={p}
                speakerImages={speakerImages}
                localStartMoments={localStartMoments}
              />
            );
            break;
          default:
            program = (
              <SimpleDayProgram
                program={p}
                speakerImages={speakerImages}
                localStartMoments={localStartMoments}
              />
            );
            break;
        }

        return (
          <ThemeProvider
            theme={{ colors: { ...themeForKind(p.kind) } }}
            key={i}
          >
            {program}
          </ThemeProvider>
        );
      })}
    </React.Fragment>
  );
};

const ProgramDay: React.FC<ProgramDayProps> = ({
  pageContext: { day, programs, programDays },
  data,
}) => {
  const sortedPrograms = programs
    .sort((a: any, b: any) => a.time_start - b.time_start)
    .filter((p: any) => p.active !== 'FALSE');

  const speakerImages = data.speakerImages.edges.reduce(
    (acc: any, { node }: any) => {
      acc[node.relativePath.split('/').pop()] =
        node.childImageSharp.fluid.srcSet;
      return acc;
    },
    {},
  );

  console.log(programDays);

  return (
    <ProgramLayout programDays={programDays}>
      {({ tzOffset }) => (
        <Container>
          <H1 sx={{ mb: 4 }}>{DayThemes[parseInt(day)]}</H1>
          <ProgramsList
            programs={sortedPrograms}
            tzOffset={tzOffset}
            speakerImages={speakerImages}
          />
        </Container>
      )}
    </ProgramLayout>
  );
};

export default ProgramDay;

export const query = graphql`
  query findAllSpeakerImages {
    speakerImages: allFile(filter: { relativeDirectory: { eq: "speakers" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid {
              srcSet
            }
          }
        }
      }
    }
  }
`;
